<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create Native Ad</strong>
            </div>
            <div class="text-muted">Create new Native Ad</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Campaign">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Campaign of the Native Ad.</span>
                      </template>
                      <a-select
                        v-decorator="['campaign', { initialValue: initialValue, rules: [{ required: true, message: 'Please select campaign.' }] }]"
                        placeholder="Please select a campaign" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in ortbCampaign.list.filter(ele => ele.Status === 0)" :key="obj.Id">
                          [{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Native Ad's name.</span>
                      </template>
                      <a-input type="text" placeholder="Native Ad Name" v-decorator="['Name', {
                        rules: [
                          { required: true, message: 'Name is required.' },
                          { max: 64, message: 'Maximum 64 characters allowed.' }
                        ]
                      }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Default CPM">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Default cost per mile.</span>
                      </template>
                      <a-input type="number" min="0" max="100" step="0.0001" placeholder="Default CPM"
                        v-decorator="['defaultCPM', { initialValue: 1.000, rules: [{ validator: defaultCpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Native Ad's description.</span>
                      </template>
                      <a-input placeholder="Description" v-decorator="['Description', {
                        rules: [
                          { max: 512, message: 'Maximum 512 characters allowed.' }
                        ]
                      }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domain/Brand">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Domain or brand name for your Banner Ad.</span>
                      </template>
                      <a-input placeholder="Ad Domain/Brand" v-decorator="['adDomainBrand', {
                        rules: [
                          { max: 256, message: 'Maximum 256 characters allowed.' }
                        ]
                      }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad App Bundle">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Application identifier. On Android, this should be bundle or package name (e.g.,
                          com.foo.mygame), on iOS, this is numeric ID.</span>
                      </template>
                      <a-input type="text" placeholder="Ad App Bundle" v-decorator="['adAppBundle', {
                        rules: [
                          { max: 64, message: 'Maximum 64 characters allowed.' }
                        ]
                      }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Destination URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Destination URL that loads when user clicks your Native Ad.</span>
                      </template>
                      <a-input type="url" placeholder="Destination URL" v-decorator="['destinationURL', {
                        rules: [
                          { required: true, message: 'Destination URL is required.' },
                          { max: 512, message: 'Maximum 512 characters allowed.' }
                        ]
                      }]">
                        <a-tooltip slot="addonBefore" trigger="click" :event-trackersStyle="{ 'max-width': '200%' }">
                          <template slot="title">
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                            <b>{ip}</b> - IP will be replaced.<br>
                            <b>{ua}</b> - User-agent string will be replaced.<br>
                            <b>{site_url}</b> - Site URL will be replaced.<br>
                            <b>{app_bundle}</b> - App Bundle will be replaced.<br>
                            <b>{app_name}</b> - App Name will be replaced.<br>
                            <b>{app_store_url}</b> - App Store URL will be replaced.<br>
                            <b>{cb}</b> - Cache buster: a uniquely generated character sequence for each link in order to prevent page caching.<br>
                            <b>{timestamp}</b> - Timestamp generated at the time of transaction.<br>
                            <b>{schain}</b> - Serialized schain parameter.<br>
                            <b>{lat}</b> - Latitude will be replaced.<br>
                            <b>{lon}</b> - Longitude will be replaced.<br>
                            <b>{ifa}</b> - Identifier for Advertisers will be replaced.<br>
                            <b>{cid}</b> - Click ID will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Click Trackers">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of third-party tracker URLs to be fired on click of the URL.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="linkClicktrackers" placeholder="Click Trackers" :showSearch="true"
                          :filterOption="true" optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('linkClicktrackers')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Fallback URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Fallback URL for deeplink. To be used if the URL given in Destination URL is not supported
                          by the device.</span>
                      </template>
                      <a-input type="url" placeholder="Fallback URL" v-decorator="['linkFallback', {
                        rules: [
                          { max: 512, message: 'Maximum 512 characters allowed.' }
                        ]
                      }]">
                        <a-tooltip slot="addonBefore" trigger="click" :event-trackersStyle="{ 'max-width': '200%' }">
                          <template slot="title">
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                            <b>{ip}</b> - IP will be replaced.<br>
                            <b>{ua}</b> - User-agent string will be replaced.<br>
                            <b>{site_url}</b> - Site URL will be replaced.<br>
                            <b>{app_bundle}</b> - App Bundle will be replaced.<br>
                            <b>{app_name}</b> - App Name will be replaced.<br>
                            <b>{app_store_url}</b> - App Store URL will be replaced.<br>
                            <b>{cb}</b> - Cache buster: a uniquely generated character sequence for each link in order to prevent page caching.<br>
                            <b>{timestamp}</b> - Timestamp generated at the time of transaction.
                            <b>{schain}</b> - Serialized schain parameter.<br>
                            <b>{lat}</b> - Latitude will be replaced.<br>
                            <b>{lon}</b> - Longitude will be replaced.<br>
                            <b>{ifa}</b> - Identifier for Advertisers will be replaced.<br>
                            <b>{cid}</b> - Click ID will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Imp. Trackers">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Impression tracking URLs, expected to return a 1x1 image or 204 response - typically only
                          passed when using 3rd party trackers.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="impTrackers" placeholder="Impression Trackers" :showSearch="true"
                          :filterOption="true" optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('impTrackers')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="JS Tracker">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Optional JavaScript impression tracker. This is a valid HTML, Javascript is already wrapped
                          in &lt;script&gt; tags. It should be executed at impression time where it can be
                          supported.</span>
                      </template>
                      <a-textarea :auto-size="{ minRows: 2 }" v-decorator="['jstracker']" style="margin-bottom: 0.75em"
                        placeholder="JavaScript Tracker HTML" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Privacy URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Privacy notice URL.</span>
                      </template>
                      <a-input type="url" placeholder="Privacy URL" v-decorator="['privacyUrl', {
                        rules: [
                          { max: 512, message: 'Maximum 512 characters allowed.' }
                        ]
                      }]">
                        <a-tooltip slot="addonBefore" trigger="click" :event-trackersStyle="{ 'max-width': '200%' }">
                          <template slot="title">
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                            <b>{ip}</b> - IP will be replaced.<br>
                            <b>{ua}</b> - User-agent string will be replaced.<br>
                            <b>{site_url}</b> - Site URL will be replaced.<br>
                            <b>{app_bundle}</b> - App Bundle will be replaced.<br>
                            <b>{app_name}</b> - App Name will be replaced.<br>
                            <b>{app_store_url}</b> - App Store URL will be replaced.<br>
                            <b>{cb}</b> - Cache buster: a uniquely generated character sequence for each link in order to prevent page caching.<br>
                            <b>{timestamp}</b> - Timestamp generated at the time of transaction.<br>
                            <b>{schain}</b> - Serialized schain parameter.<br>
                            <b>{lat}</b> - Latitude will be replaced.<br>
                            <b>{lon}</b> - Longitude will be replaced.<br>
                            <b>{ifa}</b> - Identifier for Advertisers will be replaced.<br>
                            <b>{cid}</b> - Click ID will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;"></a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <!-- left column -->
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Layout">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The Layout ID of the Native Ad unit. Used for version <b>1.0</b> of Dynamic Native Ads
                          API.</span>
                      </template>
                      <a-select placeholder="Select Layout" v-decorator="['layout', {
                        initialValue: -1,
                      }]">
                        <a-select-option :value="-1">Any</a-select-option>
                        <a-select-option :value="1">Content Wall</a-select-option>
                        <a-select-option :value="2">App Wall</a-select-option>
                        <a-select-option :value="3">News Feed</a-select-option>
                        <a-select-option :value="4">Chat List</a-select-option>
                        <a-select-option :value="5">Carousel</a-select-option>
                        <a-select-option :value="6">Content Stream</a-select-option>
                        <a-select-option :value="7">Grid adjoining the content</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Unit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The Ad unit ID of the Native Ad unit. Used for version <b>1.0</b> of Dynamic Native Ads
                          API.</span>
                      </template>
                      <a-select placeholder="Select Ad Unit" v-decorator="['adUnit', {
                        initialValue: -1,
                      }]">
                        <a-select-option :value="-1">Any</a-select-option>
                        <a-select-option :value="1">Paid Search Units</a-select-option>
                        <a-select-option :value="2">Recommendation Widgets</a-select-option>
                        <a-select-option :value="3">Promoted Listings</a-select-option>
                        <a-select-option :value="4">In-Ad (IAB Standard) with Native Element Units</a-select-option>
                        <a-select-option :value="5">Custom/Can't Be Contained</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Context">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The context in which the ad appears.</span>
                      </template>
                      <a-select placeholder="Select Context" @change="handleContextChange" v-model="contextValue">
                        <a-select-option :value="-1">Any</a-select-option>
                        <a-select-option :value="1">Content</a-select-option>
                        <a-select-option :value="2">Social</a-select-option>
                        <a-select-option :value="3">Product</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <!-- right column -->
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Context Subtype">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>A more detailed context in which the ad appears.</span>
                      </template>
                      <a-select placeholder="Select Context Subtype" v-model="contextSubtype">
                        <a-select-option :disabled="false" :value="-1">Any</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="10">General or mixed
                          content.</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="11">Primarily article content (which of
                          course could include images, etc as part of the article)</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="12">Primarily video
                          content</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="13">Primarily audio
                          content</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="14">Primarily image
                          content</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="15">User-generated content - forums,
                          comments, etc.</a-select-option>
                        <a-select-option :disabled="contextValue !== 2" :value="20">General social content such as a
                          general social network</a-select-option>
                        <a-select-option :disabled="contextValue !== 2" :value="21">Primarily email
                          content</a-select-option>
                        <a-select-option :disabled="contextValue !== 2" :value="22">Primarily chat/IM
                          content</a-select-option>
                        <a-select-option :disabled="contextValue !== 3" :value="30">Content focused on selling products,
                          whether digital or physical</a-select-option>
                        <a-select-option :disabled="contextValue !== 3" :value="31">Application
                          store/marketplace</a-select-option>
                        <a-select-option :disabled="contextValue !== 3" :value="32">Product reviews site primarily (which
                          may sell product secondarily)</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Placement Type">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The design/format/layout of the ad unit being offered.</span>
                      </template>
                      <a-select placeholder="Select Placement Type" v-decorator="['plcmtType', {
                        initialValue: -1,
                      }]">
                        <a-select-option :value="-1">Any</a-select-option>
                        <a-select-option :value="1">In the feed of content - for example as an item inside the organic
                          feed/grid/listing/carousel</a-select-option>
                        <a-select-option :value="2">In the atomic unit of the content - IE in the article page or single
                          image page</a-select-option>
                        <a-select-option :value="3">Outside the core content - for example in the ads section on the right
                          rail, as a banner - style placement near the content, etc.</a-select-option>
                        <a-select-option :value="4">Recommendation widget, most commonly presented below the article
                          content</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout the
                          day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: 0 }]" />
                    </a-tooltip>
                  </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: 0 }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Clicks Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of clicks allowed per day. The system will even out distribution of clicks throughout the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Clicks Daily Limit"
                        v-decorator="['clicksDailyLimit', { initialValue: 0 }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Spent Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to spend on Native Ad. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Spent Daily Limit"
                        v-decorator="['spentDailyLimit', { initialValue: 0 }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                </div>
              <a-divider style="margin-top: 34px;">Event Trackers</a-divider>
              <div class="event-trackers-form">
                <a-tooltip placement="top">
                  <a-select v-model="trackerEvent" class="event-trackers-select" placeholder="Select Event">
                    <a-select-option :value="-1" disabled>Event</a-select-option>
                    <a-select-option label="Select Banner" :value="1">
                      Impression
                    </a-select-option>
                    <a-select-option label="Select Banner" :value="2">
                      50% in view for 1 second
                    </a-select-option>
                    <a-select-option label="Select Banner" :value="3">
                      100% in view for 1 second
                    </a-select-option>
                    <a-select-option label="Select Banner" :value="4">
                      Video 50% in view for 2 seconds
                    </a-select-option>
                  </a-select>
                </a-tooltip>
                <a-tooltip placement="top">
                  <a-select v-model="trackerMethod" class="event-trackers-select" placeholder="Select Method">
                    <a-select-option :value="-1" disabled>Method</a-select-option>
                    <a-select-option label="Select Banner" :value="1">
                      img
                    </a-select-option>
                    <a-select-option label="Select Banner" :value="2">
                      js
                    </a-select-option>
                  </a-select>
                </a-tooltip>

                <a-tooltip placement="top">
                  <a-input class="event-trackers-input" v-model="url" type="url" placeholder="URL" />
                </a-tooltip>

                <a-button class="px-2 py-0 submit-button" type="primary" @click="addEventTracker">Add +</a-button>
                <a-button class="mobile-submit-button" type="primary" @click="handleSubmit">Create Event</a-button>
              </div>
              <b-table :bordered="true" :fields="fields" :hover="true" :items="eventTrackers" :small="true"
                :striped="true" show-empty stacked="md" class="mt-4">
                <template #cell(Preview)="data">
                  <b-button class="btn-info mr-1" size="sm"
                    @click="openPreview(data.item.StaticCreativeId, data.item.Html, data.item.IframeUrl)">
                    <i class="fa fa-eye"></i>
                  </b-button>
                </template>
                <template #cell(Name)="data">
                  <div class="d-block d-sm-none">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Event Tracker's name.</span>
                      </template>
                      <a-input-search class="event-trackers-input" placeholder="Event Tracker Name"
                        v-model="data.item.Name" size="small" @search="updateEventName(data.item.Id, data.item.Name)">
                        <a-button slot="enterButton">
                          <span style="fontSize: 90%;" class="material-icons-outlined">
                            done
                          </span>
                        </a-button>
                      </a-input-search>
                    </a-tooltip>
                  </div>
                  <div class="d-none d-sm-block">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Event Tracker's name.</span>
                      </template>
                      <a-input-search class="event-trackers-input" placeholder="Event Tracker Name"
                        v-model="data.item.Name" size="small" @search="updateEventName(data.item.Id, data.item.Name)">
                        <a-button slot="enterButton">
                          <span style="fontSize: 90%;" class="material-icons-outlined">
                            done
                          </span>
                        </a-button>
                      </a-input-search>
                    </a-tooltip>
                  </div>
                </template>
                <template #cell(Event)="data">
                  <div>
                    {{ eventTypes[data.item.event] }}
                  </div>
                </template>
                <template #cell(Method)="data">
                  <div>
                    {{ methodTypes[data.item.method] }}
                  </div>
                </template>
                <template #cell(Url)="data">
                  <div class="d-block d-sm-none">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>URL</span>
                      </template>
                      <a-input class="" placeholder="URL" v-model="data.item.url" size="small"
                        @search="updateEventUrl(data.item.url)">
                      </a-input>
                    </a-tooltip>
                  </div>
                  <div class="d-none d-sm-block">
                    <a-tooltip placement="top">
                      <a-input class="" placeholder="URL" v-model="data.item.url" size="small"
                        @search="updateEventUrl(data.item.Id, data.item.url)">
                      </a-input>
                    </a-tooltip>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <b-button class="btn-info mr-1" size="sm" @click="deleteEventTracker(data.item)">
                    <i class="fa fa-trash"></i>
                  </b-button>
                </template>
              </b-table>
              <a-divider style="margin-top: 34px;">Categories & Ad Attributes</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelColCat" :wrapper-col="wrapperColCat" label="Ad IAB Categories">
                    <a-tree checkable v-model="checkedKeys" :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedKeys" :tree-data="treeData" @expand="onExpand" @select="onSelect"
                      optionFilterProp="children" />
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelColCat" :wrapper-col="wrapperColCat" label="Ad Attributes">
                    <a-tree class="break-line" checkable v-model="checkedAds" :selected-keys="selectedAdKeys"
                      :tree-data="adTreeData" @expand="onExpand" @select="onAdSelect" optionFilterProp="children" />
                  </a-form-item>
                </div>
              </div>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create Native Ad</button>
                  <button type="button" @click="$router.push({ name: 'display-nativeads' })"
                    class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getIabCategories } from '@/api/info'
import { createNativeAd } from '@/api/display/nativead'
import adTreeData from '@/views/display/data/blockedAds.json'
import { Modal } from 'ant-design-vue'
import Vue from 'vue'

export default {
  components: {
  },
  computed: {
    ...mapState(['ortbCampaign']),
    initialValue() {
      if (this.$route.params.campaignId) {
        const campaign = this.ortbCampaign.list.find(ele => ele.Id === parseInt(this.$route.params.campaignId))
        return campaign === undefined ? [] : campaign.Id
      } else {
        return []
      }
    },
    linkClicktrackersString() {
      return this.linkClicktrackers.join('\n')
    },
    impTrackersString() {
      return this.impTrackers.join('\n')
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      trackerEvent: -1,
      trackerMethod: -1,
      url: null,
      eventTrackers: [],
      eventTypes: { 1: 'Impression', 2: '50% in view for 1 second', 3: '100% in view for 1 second', 4: 'Video 50% in view for 2 seconds' },
      methodTypes: { 1: 'img', 2: 'js' },
      fields: [
        {
          key: 'Event',
          label: 'Event',
          show: true,
          headerTitle: 'Event',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'width: 220px',
        },
        {
          key: 'Method',
          label: 'Method',
          show: true,
          headerTitle: 'Method',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'width: 80px',
        },
        {
          key: 'Url',
          label: 'URL',
          show: true,
          headerTitle: 'URL',
          sortable: false,
          tdClass: 'align-middle',
          // thStyle: 'max-height:200px; width:220px;',
        },
        {
          key: 'actions',
          label: '',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
      ],
      checkedKeys: [],
      checkedAds: [],
      selectedAdKeys: [],
      autoExpandParent: false,
      selectedKeys: [],
      treeData: [],
      adTreeData: adTreeData,
      unformattedResponse: [],
      linkClicktrackers: [],
      impTrackers: [],
      contextValue: -1,
      contextSubtype: -1,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      columns: [
        {
          title: 'Preview',
          dataIndex: 'preview',
          key: 'preview',
        },
        // { title: 'Age', dataIndex: 'age', key: 'age' },
        {
          title: 'Action',
          dataIndex: '',
          align: 'center',
          key: 'x',
          scopedSlots: { customRender: 'action' },
        },
      ],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelColCat: {
        xs: { span: 18 },
        sm: { span: 5 },
      },
      wrapperColCat: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    }
  },
  watch: {
    checkedKeys(val) {
      // console.log('onCheck', val)
    },
  },
  methods: {
    checkUrl(url) {
      var pattern = new RegExp('^https?:\\/\\/' + // this part explicitly requires "http://" or "https://"
        '(www\\.)?' +
        '([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}' +
        '(\\:[0-9]+)?' +
        '(\\/[a-zA-Z0-9\\-\\.\\_\\~\\:\\/\\?\\#\\[\\]\\@\\!\\$\\&\\\'\\(\\)\\*\\+\\,\\;\\=]*)?$',
      )
      return !!pattern.test(url)
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth
      this.height = document.documentElement.clientHeight
    },
    handleContextChange() {
      if (this.contextValue === 1) {
        this.contextSubtype = 10
      } else if (this.contextValue === 2) {
        this.contextSubtype = 20
      } else if (this.contextValue === 3) {
        this.contextSubtype = 30
      }
    },
    isValidCombination(newEvent) {
      const existingCombinations = this.eventTrackers.map(event => `${event.event}-${event.method}`)
      const newCombination = `${newEvent.event}-${newEvent.method}`
      return !existingCombinations.includes(newCombination)
    },
    addEventTracker() {
      if (this.trackerEvent && this.trackerMethod && this.url && this.trackerEvent !== -1 && this.trackerMethod !== -1) {
        if (!this.checkUrl(this.url)) {
          Vue.prototype.$notification.warning({
            message: 'URL not valid.',
            description: 'Please enter a valid URL.',
          })
          return
        }
        const newEvent = { event: this.trackerEvent, method: this.trackerMethod, url: this.url }
        if (this.isValidCombination(newEvent) && this.eventTrackers.length < 8) {
          this.eventTrackers.push(newEvent)
          this.trackerEvent = -1
          this.trackerMethod = -1
          this.url = ''
        } else {
          Vue.prototype.$notification.warning({
            message: 'This event-method combination already exists.',
          })
        }
      } else if (!this.url || this.trackerEvent === -1 || this.trackerMethod === -1) {
        Vue.prototype.$notification.warning({
          message: 'Not enough information.',
          description: 'Event, Method and URL are required.',
        })
      }
    },
    deleteEventTracker(target) {
      Modal.confirm({
        title: 'Are you sure you want to delete this event tracker?',
        onOk: () => {
          this.eventTrackers = this.eventTrackers.filter(item => item !== target)
        },
      })
    },

    updateEventUrl(url) {
    },

    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))
          values.Creatives = []
          values.AdIabCategories = newArr
          values.AdAttributes = this.checkedAds
          values.LinkClicktrackers = this.linkClicktrackers
          values.Imptrackers = this.impTrackers
          values.Context = this.contextValue
          values.ContextSubtype = this.contextSubtype
          values.EventTrackers = this.eventTrackers
          if (values.destinationURL !== '' && values.destinationURL) {
            values.destinationURL = values.destinationURL.replace(/\s/g, '')
          }
          if (values.linkFallback !== '' && values.linkFallback) {
            values.linkFallback = values.linkFallback.replace(/\s/g, '')
          }
          if (values.privacyUrl !== '' && values.privacyUrl) {
            values.privacyUrl = values.privacyUrl.replace(/\s/g, '')
          }
          createNativeAd(values).then(response => {
            if (response.Status === 'Ok') {
              this.$notification.success({
                message: 'Native Ad Created',
                description: `Native Ad ${response.Nativead.Name} has been successfully created.`,
              })
              // this.$router.push('/display/nativead/update/' + response.Nativead.Id)
              this.$router.push({
                name: 'display-nativead-update',
                params: { id: response.Nativead.Id },
              }).catch(() => {
              })
            } else if (typeof response.type !== 'undefined' && response.type === 'error') {
              this.$notification.success({
                message: 'Native Ad Created',
                description: 'Native Ad has been successfully created.',
              })
            }
          }).catch(err => console.log(err))
          // const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          // const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          // const newArr = this.checkedKeys.filter(el => !array.includes(el))
          // values.AdIabCategories = newArr
          // values.BlockedAdAttributes = this.checkedAds
          // this.$store.dispatch('nativead/CREATE_AD', { payload: values, callback: function() { this.$router.push('/nativeads') }.bind(this) })
        }
      })
    },
    copyList(type) {
      if (type === 'linkClicktrackers') {
        navigator.clipboard.writeText(this.linkClicktrackersString)
        Vue.prototype.$notification.success({
          message: 'Link Clicktrackers copied to clipboard.',
        })
      } else if (type === 'impTrackers') {
        navigator.clipboard.writeText(this.impTrackersString)
        Vue.prototype.$notification.success({
          message: 'Imp. Trackers copied to clipboard.',
        })
      }
    },
    onAdSelect(selectedAdKeys, info) {
      // console.log('onSelect', info)
      this.selectedAdKeys = selectedAdKeys
    },
    onCheck(checkedKeys) {
      // console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },
    onBlockedAdCheck(checkedAds) {
      // console.log('onCheck', checkedAds)
      this.checkedAds = checkedAds
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    defaultCpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Default CPM can not be negative.')
      }
      message()
    },
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions)
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions)
  },
  created() {
    getIabCategories().then(response => {
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
    })
    this.$store.dispatch('ortbCampaign/LOAD_CAMPAIGNS_SHORT')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}

.m-divider {
  margin-top: 2.5em;
}

.break-line>>>li .ant-tree-node-content-wrapper {
  margin-bottom: 5px;
  padding-top: 4px;
  display: inline-block !important;
  white-space: pre-wrap !important;
  line-height: 15px !important;
  width: 90% !important;
}

.creative {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-left: 3em;
  padding-right: 3em;
}

.pointer {
  cursor: pointer;
}

.box {
  height: 8em;
  width: 8em;
  /* border: 0; */
  overflow: none;
  padding: 0;
  background-color: #f0f2f4;
}

.selected-outline {
  outline: thick solid #e5471c
}

.header-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

.header-bottom {
  height: 5em;
  margin-top: 3em;
}

.select-container {
  display: flex;
  justify-content: center;
  color: #f0f2f4;
}

.header-split {
  height: 3em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.header-top {
  top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #557df6;
}

.header-bottom {
  bottom: 0;
  background-color: none;
}

.footer-split {
  display: inline-flex;
  align-self: flex-end;
  height: 3em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.footer-top {
  top: 63%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #557df6;
}

.footer-bottom {
  bottom: 0;
  background-color: none;
}

.sidebar-split {
  height: 8em;
  width: 3em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.sidebar-left {
  left: 63%;
  writing-mode: vertical-lr;
  text-orientation: sideways-right;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #557df6;
}

.sidebar-right {
  right: 0;
  background-color: none;
}

.above-split {
  height: 5em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.above-top {
  top: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #557df6;
}

.above-bottom {
  bottom: 0;
  background-color: none;
}

.below-split {
  display: inline-flex;
  align-self: flex-end;
  height: 5em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.below-top {
  top: 38%;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #557df6;
}

.below-bottom {
  bottom: 0;
  background-color: none;
}

.list-flex {
  display: flex;
  align-content: flex-start;
}

.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}

@media screen and (min-width: 1208px) {
  .event-trackers-form {
    display: flex;
    justify-content: center;
  }

  .event-trackers-select {
    min-width: 15em;
    max-width: 18em;
    margin-right: 0.5em;
  }

  .event-trackers-input {
    max-width: 15em;
    margin-right: 0.5em;
  }

  .submit-button {
    display: block;
  }

  .mobile-submit-button {
    display: none;
  }
}

@media screen and (max-width: 1208px) {
  .submit-button {
    display: none;
  }

  .mobile-submit-button {
    display: block;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .w-45 {
    width: 45% !important;
  }

  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

;

@media only screen and (max-width: 768px) {
  .mobile-mb {
    margin-bottom: 1em;
  }

  .-m {
    margin-right: -4rem;
  }

  .centered {
    justify-content: space-evenly;
  }

  .creative {
    display: flex;
    flex-direction: column;
  }
}

.box {
  height: 8em;
  width: 8em;
  border: 0;
  padding: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  background: #f0f2f4;
}

;
</style>
